import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import SaveSearchButton from '@components/searchPage/SaveSearchButton';
import '../style.scss';

const TitleAndSaveButton = ({
  title,
  h2,
  aliasH2,
  publisherName,
  setForcedAuction,
  checkProposeBkmAfterLead,
  userLogIn,
  count,
  showSaveSearchButton,
  showSaveSearchBannerGreenHint,
  fewResults,
  copySaveSearch,
}) => {
  return (
    <Grid justify="space-between" align="center">
      <GridItem className="pr--m">
        <h1 data-count={count} className="srp-hdr__h1 tp-w--l c-txt--f0">
          {`${title} ${h2}`}
        </h1>
        {aliasH2 && (
          <h2 className="srp-hdr__h2 tp-w--m tp-s--m c-txt--f2">{aliasH2}</h2>
        )}
        {fewResults && fewResults.page > 1 && (
          <p className="srp-hdr__few mt--xs tp-w--m tp-s--l c-txt--f0">
            Stai vedendo gli annunci vicini alla tua area di ricerca -{' '}
            <span className="tp-w--l">pagina {fewResults.page}</span>
          </p>
        )}

      </GridItem>
      {showSaveSearchButton && (
        <SaveSearchButton
          publisherName={publisherName}
          setForcedAuction={setForcedAuction}
          checkProposeBkmAfterLead={checkProposeBkmAfterLead}
          userLogIn={userLogIn}
          notShownOn="mobile"
          copySaveSearch={copySaveSearch}
          positionGTM="SRP_PageTitle"
          showSaveSearchBannerGreenHint={showSaveSearchBannerGreenHint}
        />
      )}
    </Grid>
  );
};

TitleAndSaveButton.propTypes = {
  title: PropTypes.string,
  h2: PropTypes.string,
  aliasH2: PropTypes.string,
  publisherName: PropTypes.string,
  setForcedAuction: PropTypes.func,
  checkProposeBkmAfterLead: PropTypes.func,
  userLogIn: PropTypes.func,
  count: PropTypes.string,
  showSaveSearchButton: PropTypes.bool,
  showSaveSearchBannerGreenHint: PropTypes.bool,
  fewResults: PropTypes.instanceOf(Object),
  copySaveSearch: PropTypes.instanceOf(Object),
};

TitleAndSaveButton.defaultProps = {
  title: '',
  h2: '',
  aliasH2: '',
  publisherName: '',
  setForcedAuction: () => { },
  checkProposeBkmAfterLead: () => { },
  userLogIn: () => { },
  count: '',
  showSaveSearchButton: true,
  showSaveSearchBannerGreenHint: false,
  fewResults: {},
  copySaveSearch: {},
};

export default TitleAndSaveButton;

import React, { useState, useEffect } from 'react';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import makeClassName from 'shared-utils/src/makeClassName';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';
import parseJSON from 'shared-utils/src/parseJSON';
import Button from 'shared-components/components/Button';
import CloseL from 'shared-vectors/icons/CloseL';
import Stared from 'shared-vectors/icons/Stared';
import Icon from 'shared-vectors/src/components/Icon';

import { useCtxGlobal } from '@client/app/Container';

import './style.scss';

const DownloadAppBannerFixed = ({ onClose }) => {
  const {
    globalState: {
      currentUrl,
    },
  } = useCtxGlobal();

  const [isClosed, setIsClosed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const timestampIsExpired = ({ tsToCheck = new Date().getTime(), timestampToAdd = 0 }) => {
    const today = new Date().getTime();
    if (today > (tsToCheck + timestampToAdd)) {
      return true;
    }
    return false;
  };

  useEffect(
    () => {
      const isBannerShowable = () => {

        const tmpLSAppDownload = window.localStorage.getItem('_downloadAppBanner');
        const lsAppDownload = parseJSON(tmpLSAppDownload);

        if (lsAppDownload) {
          const isExpiredLSTimestamp = timestampIsExpired({ tsToCheck: lsAppDownload.timestamp });
          if (!isExpiredLSTimestamp) {
            if (!isClosed) {
              setIsClosed(true);
              onClose();
            }
            return false;
          }
          localStorage.removeItem('_downloadAppBanner');
        }
        return true;
      };
      setIsVisible(isBannerShowable());
    }, [],
  );

  const encodedUrl = encodeURIComponent(currentUrl);
  const downloadUrl = `https://fdyn.casa.it/?link=${encodedUrl}&apn=it.casa.app&ibi=com.rea-group.reapa-casa&isi=470456810&amv=3006008&utm_source=web-casa&utm_campaign=continueInApp&utm_medium=direct&pt=127572684&ct=continueInApp`;

  const bannerClick = (e) => {
    e.preventDefault();
    sleep(300).then(() => trackGTMEvents({
      category: 'MigrationToApp',
      action: 'DeferredOpenInApp',
      label: currentUrl,
    }));
    window.open(downloadUrl);
  };

  const closeBanner = (e) => {
    e.preventDefault();
    sleep(300).then(() => trackGTMEvents({
      category: 'MigrationToApp',
      action: 'CloseStickyBottomBar',
    }));
    let timestampToSet = new Date();
    timestampToSet = timestampToSet.setTime(timestampToSet.getTime() + (10 * 24 * 60 * 60 * 1000)); // 10 days
    localStorage.setItem('_downloadAppBanner', JSON.stringify({
      day: 10,
      type: 'firstClosure',
      timestamp: timestampToSet,
    }));
    setIsClosed(true);
    onClose();
    setIsVisible(!isVisible);
  };
  const bannerCls = makeClassName([
    'dwnld-app',
  ]);

  return (
    isVisible &&
    <Grid className={bannerCls} align="center">
      <GridItem behavior="fixed">
        <button className="dwnld-app__btn_close" onClick={e => closeBanner(e)} value="" title="" type="button">
          <Icon name={CloseL} className="dwnld-app__btn_close__icn_close" color="f1" size="s" />
        </button>
      </GridItem>
      <GridItem behavior="fixed">
        <img className="dwnld-app__grid-img-el" alt="Casa App Logo" src="/portal-srp/assets/img/casalogoonly.png" />
      </GridItem>
      <GridItem className="dwnld-app__text tp-w--l tp-s--l grid-item">
        <div className="tp-w--l" id="h1_dwnl_app">Continua con l&apos;App</div>
        {/* <div className="tp-w--l" id="h2_dwnl_app">è più semplice</div>  */}
        <div className="dwnld-app__stars" id="h2_dwnl_app">
          {[1, 2, 3, 4].map(i => <Icon key={`star_${i}`} id={`star_${i}`} name={Stared} className="dwnld-app__stars__star mr--xs" color="f1" size="m" />)}
          <div className="dwnld-app__stars__star dwnld-app__stars__star__blurred mr--xs" />
        </div>

      </GridItem>
      <GridItem behavior="fixed" className="mr--m">
        <Button
          className="dwnld-app__btn_open dwnld-app__btn_open-small  tp-w--m tp-s--s pr--ms pl--ms"
          action={bannerClick}
          value="Apri"
          title="Download app"
          size="s"
          id="cta_dwnl_app"
        />
        <Button
          className="dwnld-app__btn_open dwnld-app__btn_open-large tp-w--m tp-s--s"
          action={bannerClick}
          value="Apri l'App"
          title="Download app"
          size="s"
          id="cta_dwnl_app"
        />
      </GridItem>
    </Grid>
  );
};

export default DownloadAppBannerFixed;
